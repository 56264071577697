<template>
  <b-modal
    id="leave-page-details-modal"
    :title="title"
    title-class="h4 font-weight-bold"
    lazy
    size="lg"
    hide-footer
  >
    <div class="d-flex px-3 pb-3 flex-column">
      <LeavePageDetailsEditForm></LeavePageDetailsEditForm>
    </div>
  </b-modal>
</template>

<script>
import LeavePageDetailsEditForm from "@/modules/company/components/leave/type/LeavePageDetailsEditForm";
import { mapGetters } from "vuex";
export default {
  name: "LeavePageDetailsModal",
  components: { LeavePageDetailsEditForm },
  data() {
    return {
      currentTab: 0,
      busy: false,
    };
  },
  computed: {
    ...mapGetters({ leave: "getSelectedCompanyLeave" }),
    title() {
      return `Modify ${this.leave.name}`;
    },
  },
};
</script>

<style scoped></style>
