<template>
  <div>
    <b-form class="form" v-if="!formIsBusy" v-on:submit.prevent="onSubmit">
      <b-alert show="" variant="light">
        Click <strong>Submit</strong> to confirm update the Leave Type
      </b-alert>
      <b-form-group id="type" label-cols-lg="2" :label="$t('DETAILSFORM.TYPE')">
        <b-form-input
          id="type"
          :placeholder="$t('DETAILSFORM.TYPE1')"
          v-model="$v.form.type.$model"
          :state="validateState('type')"
        >
        </b-form-input>
        <b-form-invalid-feedback v-if="!$v.form.type.required"
          >{{ $t("ALERT.ALERT") }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-alert show="" variant="light">
        Changing the entitlement <strong>only affects future employees</strong>.
        For current employees, please modify at the
        <strong>Leave Entitlement</strong> section.
      </b-alert>

      <!-- begin:leave unit  -->
      <b-form-group :label="$t('DETAILSFORM.UNIT')" label-cols-lg="2">
        <b-form-select
          v-model="$v.form.leaveunit.$model"
          :options="leaveunits"
          :state="validateState('leaveunit')"
        >
        </b-form-select>
        <b-form-invalid-feedback v-if="!$v.form.leaveunit.required"
          >{{ $t("ALERT.ALERT") }}
        </b-form-invalid-feedback>
      </b-form-group>

      <!-- begin: unit  -->
      <b-form-group label="Unit" label-cols-lg="2">
        <b-form-input
          type="number"
          v-model="$v.form.unit.$model"
          :state="validateState('unit')"
        >
        </b-form-input>
        <b-form-invalid-feedback v-if="!$v.form.unit.required">{{
          $t("ALERT.ALERT")
        }}</b-form-invalid-feedback>
      </b-form-group>
      <!-- end: unit  -->

      <b-form-group id="remark" label-cols-lg="2" :label="$t('JOB.REMARK')">
        <b-form-textarea
          id="remark"
          v-model="$v.form.remark.$model"
          :state="validateState('remark')"
        ></b-form-textarea>

        <b-form-invalid-feedback v-if="!$v.form.remark.required"
          >{{ $t("ALERT.ALERT") }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label-cols-lg="2" :label="$t('DETAILSFORM.PAID')">
        <b-form-checkbox
          inline
          size="lg"
          class="mt-md-2"
          v-model="$v.form.paid.$model"
          value="true"
          unchecked-value="false"
          >{{ $t("BUTTON.YES") }}
        </b-form-checkbox>
      </b-form-group>

      <div class="d-flex justify-content-end border-top pt-3">
        <b-button
          ref="employee-general-button"
          variant="primary"
          type="submit"
          class="font-size-lg font-weight-bold"
          v-bind:class="{ 'spinner spinner-right spinner-light': isBusy }"
        >
          {{ $t("EMPLOYEES.DETAILS.SUBMIT") }}
        </b-button>
      </div>
    </b-form>
    <b-skeleton-wrapper class="p-5" :loading="formIsBusy">
      <template #loading>
        <b-skeleton class="mt-2" type="input"></b-skeleton>
        <b-skeleton class="mt-5" type="input"></b-skeleton>
        <b-skeleton class="mt-10" type="input"></b-skeleton>

        <b-skeleton class="mt-5 align-self-end" type="button"></b-skeleton>
      </template>
    </b-skeleton-wrapper>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import {
  maxLength,
  minLength,
  numeric,
  required,
} from "vuelidate/lib/validators";
import { mapGetters } from "vuex";

export default {
  name: "LeavePageDetailsForm",
  mixins: [validationMixin],
  props: {
    title: {
      type: String,
      default: "Leave Type Form",
    },
  },
  data() {
    return {
      form: {
        type: "",
        remark: "",
        entitlement: "",
        leaveunit: null,
        paid: "",
        unit: "",
      },
      leaveunits: [
        { value: null, text: "Please choose the leave unit" },
        { value: "1", text: "Day" },
        { value: "2", text: "Hour" },
      ],
      daycounts: [
        { value: "1", text: "Workday" },
        { value: "2", text: "Calendar Day" },
      ],
      isBusy: false,
    };
  },
  validations: {
    form: {
      type: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(255),
      },
      remark: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(500),
      },
      unit: {
        required,
        numeric,
      },
      entitlement: {},
      leaveunit: {
        required,
      },
      paid: {},
    },
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      console.log("Submit");
      console.log("Employee Contact Details Form:", this.form);

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const submitForm = {
        id: this.getForm.id,
        name: this.form.type,
        description: this.form.remark,
        leave_unit: this.form.leaveunit,
        paid_leave: this.form.paid === "true" ?? false,
        unit: this.form.unit,
      };

      this.isBusy = true;
      this.$store
        .dispatch("modifyCompanyLeaveType", submitForm)
        .then(() => {
          this.$bvModal.msgBoxOk("Successfully modified this Leave Type", {
            title: "Modified Leave",
            centered: true,
          });
        })
        .finally(() => {
          this.$store.commit("setUpdateCompanyLeaveTypeForm", true);
          this.isBusy = false;
        });
    },
  },

  computed: {
    ...mapGetters({
      getForm: "getCompanyLeaveForm",
      formIsBusy: "getCompanyLeaveFormBusy",
    }),
  },

  watch: {
    getForm(newForm, oldForm) {
      console.log("Got new Form", newForm, oldForm);
      const appLeave = newForm.app_leave;
      this.form.type = appLeave.name;
      this.form.paid = appLeave.is_paid_leave ? "true" : "false";
      this.form.remark = appLeave.remark;
      this.form.leaveunit = appLeave.leave_unit_id;
      this.form.unit = appLeave.unit;
    },
  },
};
</script>

<style scoped></style>
