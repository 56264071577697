<template>
  <b-card>
    <div class="d-flex justify-content-between">
      <div class="flex-row">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("LEAVE.STAT") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <b-sidebar
          ref="department-list-sidebar-ref"
          id="department-list-sidebar"
          backdrop
          right
          lazy
          :title="$t('LEAVE.LEAVEDETAILS')"
          sidebar-class="offcanvas"
          header-class="offcanvas-header d-flex mt-5 ml-5 mr-5"
          bg-variant="white"
        >
          <template v-slot:header-close>
            <button
              class="btn btn-sm btn-icon btn-light-secondary btn-hover-danger"
            >
              <i class="ki ki-close icon-xs"></i>
            </button>
          </template>
        </b-sidebar>
      </div>
    </div>
    <div>
      <b-alert
        variant="info"
        show="5"
        fade
        dismissible
        v-if="formData.length > 0"
      >
        {{ formData }}
      </b-alert>
    </div>

    <LeavePageStatisticsTable :reload="reload"></LeavePageStatisticsTable>
  </b-card>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { alert } from "sweetalert2";
import LeavePageStatisticsTable from "@/modules/company/components/leave/statistics/LeavePageStatisticsTable";

export default {
  name: "LeavePageDetails",
  components: { LeavePageStatisticsTable },
  mounted() {
    console.log("Leave Type List");
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("SIDEBAR.COMPANY") },
      {
        title: this.$t("SIDEBAR.DEPARTMENT"),
        route: "/company/department/list",
      },
    ]);
  },
  data() {
    return {
      formData: "",
      reload: false,
    };
  },
  methods: {
    testClose(data) {
      console.log(data);
      this.formData = "Successfully added department";
      console.log(this.formData);

      const sideBar = this.$refs["department-list-sidebar-ref"];
      sideBar.hide();

      this.$store.dispatch("getDepartment").catch(() => {
        alert({
          title: "Failed",
        });
      });
    },

    refresh() {
      this.reload = !this.reload;
    },
  },
};
</script>

<style scoped></style>
